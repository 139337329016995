.not-logged-in #tab {
	display: none;
}
#tab {
	margin: 20px auto;
}
#tab ul.primary {
	padding: 0;
	border: 0px none;
	margin: 0;
}
.front #tab ul.primary {
	padding: 0 0 0 10px;
}
#tab ul.primary li {
	margin-right: 10px;
	display: inline-block;
	overflow: hidden;
	border: 1px solid #888;
	vertical-align: bottom;
}
#tab ul.primary li a {
	margin: 0;
	padding: 5px;
	display: block;
	border: 0px none;
}